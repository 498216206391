<template>
  <div class="relative">
    <header
      :class="{' drop-shadow-[0_4px_8px_rgba(0_0_0_/_5%)]' : mobileMenuState < 1}"
      class="fixed z-[60] block w-full overflow-y-auto bg-white px-4 py-2.5 lg:relative lg:h-screen lg:px-5 lg:pt-10"
    >
      <div class="hidden h-full flex-col justify-between gap-4 lg:flex">
        <div>
          <div class="flex justify-center">
            <UiLogo />
          </div>
          <div class="-ml-1 mt-7 flex flex-col items-center">
            <UiMenuDesktop :menu-items="menuItems" />
          </div>
        </div>
        <div class="flex justify-center">
          <NuxtLink
            v-if="user && isMember"
            :to="{name: 'webshop'}"
            class="fuzzy btn btn-primary"
          >
            <span>Naar de webshop</span>
            <IconsShoppingCart class="" />
          </NuxtLink>
        </div>
        <div class="mx-auto mb-7 space-y-2.5">
          <NavItem
            :item="{
              label: 'Terug naar KHN.nl',
              link: 'https://khn.nl',
              icon: 'globe-alt'
            }"
            class="-ml-1"
          />
          <button
            v-if="user"
            type="button"
            class="-ml-1 mb-8 flex items-center gap-2 border-l-4 border-white px-4 py-2.5 text-sm font-bold no-underline transition hover:border-l-secondary hover:text-secondary lg:text-base"
            @click="signOut"
          >
            <IconsLogout />
            Uitloggen
          </button>
          <NuxtLink
            v-else
            class="btn btn-primary w-full"
            to="/auth/login"
          >
            Inloggen
            <IconsUser />
          </NuxtLink>
        </div>
      </div>
      <div class="flex justify-between gap-4 lg:hidden">
        <UiLogo small />
        <div class="flex w-auto items-center space-x-7">
          <div class="relative size-6">
            <NuxtLink :to="{name: 'webshop-cart'}">
              <IconsShoppingCart class="size-6" />
            </NuxtLink>
            <div
              v-if="shoppingCartSize > 0"
              class="absolute -right-2 -top-2 flex size-5 items-center justify-center rounded-full bg-secondary"
            >
              <span class="text-xs text-white">
                {{ shoppingCartSize.toString().padStart(2, '0') }}
              </span>
            </div>
          </div>
          <button
            class="btn btn-primary flex gap-2"
            @click="mobileMenuState = mobileMenuState > 0 ? 0 : 1"
          >
            {{ mobileMenuState === 0 ? 'Menu' : 'Sluiten' }}
            <IconsHamburger v-if="mobileMenuState === 0" />
            <IconsCross v-else />
          </button>
        </div>
      </div>
    </header>
    <UiMenuMobile
      :class="mobileMenuState === 1 ? 'translate-y-0' : '-translate-y-full'"
      :items="menuItems"
    />
  </div>
</template>

<script lang="ts" setup>
import type {Ref} from 'vue';
import {useShoppingCart} from '#composables/shoppingCart';
import type {MenuItem} from '~/types/menu-item';

const route = useRoute();
const user = useSofieUserWhenAuthenticated();
const {isPartner, isMember, isManager} = useRole();

const {signOut} = useSession();

const menuItemsLoggedIn: MenuItem[] = [
  {
    label: 'Dashboard',
    link: {name: 'dashboard'},
    icon: 'home',
  },
  {
    label: 'Account',
    link: {
      name: isMember.value ? 'account' : 'account-communicatievoorkeuren',
    },
    icon: 'user-circle',
    submenu: [
      isMember.value ? {
        label: 'Mijn profiel',
        link: {name: 'account'},
      } : null,
      isMember.value ? {
        label: 'Beveiliging',
        link: {name: 'account-beveiliging'},
      } : null,
      isMember.value ? {
        label: 'Bedrijfsgegevens',
        link: {name: 'account-bedrijfsgegevens'},
      } : null,
      isMember.value ? {
        label: 'Lidmaatschap',
        link: {name: 'account-lidmaatschap'},
      } : null,
      isMember.value ? {
        label: 'Ledenvoordelen',
        link: {name: 'account-ledenvoordelen'},
      } : null,
      isMember.value ? {
        label: 'Evenementen',
        link: {name: 'account-evenementen'},
      } : null,
      isMember.value ? {
        label: 'Mijn facturen',
        link: {name: 'account-facturen'},
      } : null,
      {
        label: 'Communicatievoorkeuren',
        link: {name: 'account-communicatievoorkeuren'},
      },
    ].filter(l => l),
  },
  isMember.value ? {
    label: 'Ledenvoordelen',
    link: {name: 'ledenvoordelen'},
    icon: 'scale',
  } : null,
  isMember.value ? {
    label: 'Trainingen',
    link: {name: 'trainingen'},
    icon: 'calendar',
  } : null,
  isMember.value ? {
    label: 'Kennisbank',
    link: {name: 'kennisbank'},
    icon: 'newspaper',
  } : null,
  isManager.value ? {
    label: 'Voor bestuurders',
    link: {name: 'bestuurders'},
    icon: 'office-building',
    submenu: [
      {
        label: 'Dashboard',
        link: {name: 'bestuurders'},
      },
      // {
      //   label: 'Bestuurderstrainingen',
      //   link: {name: 'bestuurders-bestuurderstrainingen'},
      // },
      {
        label: 'Bestuurderstrainingen',
        link: {name: 'bestuurders-slug', params: {slug: 'bestuurderstrainingen'}},
      },
      {
        label: 'Dossiers / Documenten',
        link: {name: 'bestuurders-dossiers-documenten'},
      },
      {
        label: 'Projectenpot',
        link: {name: 'bestuurders-slug', params: {slug: 'projectenpot'}},
      },
      {
        label: 'Ballotage',
        link: {name: 'bestuurders-slug', params: {slug: 'ballotage'}},
      },
      {
        label: 'Ledenraadsvergadering',
        link: {name: 'bestuurders-slug', params: {slug: 'ledenraadsvergadering'}},
      },
    ],
  } : null,
  isPartner.value ? {
    label: 'Verkoopkansen',
    link: {name: 'verkoopkansen'},
    icon: 'scale',
  } : null,
  isPartner.value ? {
    label: 'Klanten',
    link: {name: 'klanten'},
    icon: 'group',
  } : null,
  isMember.value ? {
    label: 'Vacatures',
    link: 'https://jobs.khn.nl/auth/khn',
    icon: 'group',
    target: '_blank',
  } : null,
  {
    label: 'Naar webshop',
    link: {name: 'webshop'},
    icon: 'shopping-cart',
    highlight: true,
    desktopHidden: true,
  },
].filter(l => l);

const menuItemsGuest = [
  {
    label: 'KHN Webshop',
    link: {name: 'webshop'},
    icon: 'shopping-cart',
  },
  {
    label: 'Trainingen',
    link: {name: 'trainingen'},
    icon: 'calendar',
  },
];

const menuItems: MenuItem[] = computed(() => user.value ? menuItemsLoggedIn : menuItemsGuest);

const shoppingCartSize: Ref<number> = useShoppingCart().shoppingCartSize;

const mobileMenuState = ref(0);

watch(() => route.path, () => {
  mobileMenuState.value = 0;
});
</script>
