<template>
  <div>
    <div class="flex items-center justify-between gap-2">
      <NuxtLink
        :to="item.link"
        class="fuzzy flex flex-1 items-center px-4 py-2.5 text-sm font-bold no-underline lg:text-base"
        :class="[
          {'active-link': isActive},
          {'pr-0' : item.submenu},
        ]"
        :target="item.target"
      >
        <UiIcon :name="item.icon" />
        <div
          class="ml-2"
          v-html="item.label"
        />
      </NuxtLink>
      <button
        v-if="item.submenu"
        class="-mr-2 p-2"
        @click="subMenuOpen = !subMenuOpen"
      >
        <IconsChevronRight
          :class="subMenuOpen ? '-rotate-90' : 'rotate-90'"
          class="size-3"
        />
      </button>
    </div>
    <div
      v-if="subMenuOpen"
      class="mt-2 space-y-2.5 pl-8"
    >
      <NuxtLink
        v-for="(subItem, subIndex) in item.submenu"
        :key="subIndex"
        class="flex grow items-center py-0.5 pl-2 text-sm no-underline"
        :to="subItem.link"
      >
        <div
          class="flex grow items-center gap-4"
          v-html="subItem.label"
        />
      </NuxtLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type {MenuItem} from '~/types/menu-item';

const props = defineProps<{ item: MenuItem }>();
const route = useRoute();
const isActive = ref(false);
const subMenuOpen = ref(false);

onMounted(() => {
  checkIfPageIsActive(route.path);
  checkSubMenuOpen();
});

function checkSubMenuOpen() {
  subMenuOpen.value = props.item.submenu?.some(item => getLinkName(item.link) === route.name) ?? false;
}

watch(() => route.path, (newValue) => {
  checkIfPageIsActive(newValue);
  checkSubMenuOpen();
});

function getLinkName(link: MenuItem['link']): string {
  if (typeof link === 'object') {
    return link.name;
  }
  return link;
}

function checkIfPageIsActive(path: string) {
  const pathSegments = path.split('/');
  isActive.value = getLinkName(props.item.link) === pathSegments[1];
}
</script>

<style scoped>
/* noinspection CssUnusedSymbol */
.active-svg {
  @apply text-secondary;
}

a {
  @apply transition border-l-4 border-white;
}

/* noinspection CssUnusedSymbol */
.active-link, a:hover {
  @apply border-l-secondary;
}

a:hover,
a.router-link-exact-active ::slotted(svg),
a.router-link-active.fuzzy ::slotted(svg) {
  @apply active-svg;
}

/* noinspection CssUnusedSymbol */
a.router-link-exact-active,
a.router-link-active.fuzzy {
  @apply active-link;
}
</style>
