<template>
  <div class="flex flex-col md:flex-row">
    <NavMain class="w-auto shrink-0 shadow-lg lg:w-64" />
    <main class="relative h-auto grow overflow-y-auto pt-20 md:h-screen lg:py-0">
      <div class="flex h-full flex-col">
        <div class="flex grow flex-col">
          <slot />
        </div>
      </div>
    </main>
  </div>
</template>
